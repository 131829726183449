import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-solid-svg-icons'

export default function Servicos({ data, location }) {
  const [selected, setService] = useState(null)

  const expandService = (service) =>
    setService(selected === service.tag ? null : service.tag)

  return (
    <Layout location={location} title="Serviços">
      <div
        id="servicos"
        className="flex lg:flex-no-wrap justify-center flex-wrap content-center flex-col sm:flex-row"
      >
        {data.services.list.map((service, key) => {
          const isHidden = !selected || service.frontmatter.tag === selected
          let hidden = isHidden ? ' xs:max-w-md' : ' hidden'
          hidden += selected ? ' sm:w-full' : ''
          return (
            <div
              onClick={() => expandService(service.frontmatter)}
              onKeyPress={() => expandService(service.frontmatter)}
              role="button"
              tabIndex={0}
              key={key}
              className={
                'container flex flex-col justify-between cursor-pointer sm:w-2/5 py-4 sm:px-8 px-1 sm:m-2 m-1 bg-white hover:shadow-2xl shadow-lg rounded-lg my-5' +
                hidden
              }
            >
              <div className="flex flex-col items-center">
                <h2 className="lg:text-2xl sm:text-xl text-2xl font-semibold pb-8">
                  {service.frontmatter.name}
                </h2>
                <FontAwesomeIcon
                  icon={icons[service.frontmatter.icon]}
                  size="4x"
                />
                <section
                  dangerouslySetInnerHTML={{ __html: service.html }}
                  className={
                    'sm:m-8 m-4 mb-0 text-one-700' +
                    (service.frontmatter.tag !== selected ? ' hidden' : '')
                  }
                />
              </div>
              <div className="flex justify-center pt-10">
                <a
                  href={'#' + service.frontmatter.tag}
                  className="text-sm font-medium text-red-700"
                >
                  {service.frontmatter.tag !== selected ? (
                    <FontAwesomeIcon
                      icon={icons.faChevronCircleDown}
                      size="2x"
                    />
                  ) : (
                    <FontAwesomeIcon icon={icons.faTimesCircle} size="2x" />
                  )}
                </a>
              </div>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/servicos/" } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      list: nodes {
        frontmatter {
          name
          tag
          icon
          order
        }
        html
      }
    }
  }
`
